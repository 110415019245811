.dropdownContainer {
    display: inline-block;
    position: relative;
    width: 100%;
  }
  
  .dropdownHeader {
    padding: 24px 28px;
    border: 1px solid #ddd;
    background-color: var(--app-white);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 500;
  }
  
  .arrowUp::after,
  .arrowDown::after {
    display: inline-block;
    transform: rotate(90deg);
    margin-left: 10px;
    font-size: 1.25rem;
  }
  
  .arrowUp::after {
    transform: rotate(-90deg);
  }
  
  .dropdownContent {
    border: 1px solid #ddd;
    z-index: 1;
    padding: 24px 8px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: auto;
    height: 50dvh;
  }
  