.main {
  padding: 0.75rem 1.5rem;
  background: var(--app-white);
  display: flex;
  justify-content: space-evenly;
  position: sticky;
  top: 0;
  z-index: 11;
  border-bottom: 1px solid var(--border-light-grey);
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.offCanvas {
  max-width: 18.5rem;
  background-color: var(--app-white);
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: translateX(105%);
  transition: transform 0.7s ease-in-out;
}

.activeOffCanvas {
  transform: translateX(0);
}

.offCanvasContent {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.brandLogo {
  width: 4rem;
  height: 4rem;
  border-radius: 0.5rem;
}

.brandLogo img {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.brand {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  cursor: pointer;
}

.brandName {
  color: var(--app-black);
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.navItems {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
  gap: 0.8rem;
  height: 100%;
  position: relative;
}

.navItemBtnSmall {
  padding: 0.25rem 1rem !important;
  width: 50%;
}

.navItem {
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.5rem;
  background-color: #f2f4f8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideBar{
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.6), 0 2px 3px rgba(0, 0, 0, 0.08);
}

.navSearchIcon{
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 50%;
}

.searchInput {
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  border-radius: 20px;
  font-family: Fraunces;
  width: 10rem;
  padding-left: 0;
}

.searchBar{
  display: flex;
  border: 1px solid grey;
  background-color: #fff;
  border-radius: 20px;
}

/* Dropdown container */
.dropdown {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-width: 12rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-top: 0rem;
  overflow: hidden;
}

/* Dropdown items */
.dropdownItem {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: Arial, sans-serif;
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  background-color: var(--app-white);
}

/* Hover state for dropdown items */
.dropdownItem:hover {
  background-color: #f0f0f0;
}

/* Search icon inside dropdown */
.dropdownItem .fa-searchengin {
  font-size: 1.1rem;
}

/* Limit dropdown height and allow scrolling */
.dropdown {
  max-height: 13rem; /* Adjust as needed */
  overflow-y: auto;
}

/* Scrollbar styling (optional, for better UX) */
.dropdown::-webkit-scrollbar {
  width: 8px;
}

.dropdown::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

.dropdown::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}


.navItem img {
  width: 100%;
  height: 100%;
}

.userImage {
  width: 2.25rem;
  height: 2.25rem;
  background-color: #f2f4f8;
  border-radius: 50%;
  cursor: pointer;
}

.userImage > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: fill;
}

.userName {
  font-size: 0.925rem;
  font-family: var(--inter);
  line-height: 22px;
  letter-spacing: 0.1%;
  display: flex;
  align-items: center;
  color: #5f6d7e;
  cursor: pointer;
  max-width: 11rem;
}

.collapsedItems {
  display: flex;
  gap: 0.875rem;
  justify-content: space-around;
  padding: 0.75rem 0.5rem;
  height: max-content;
  list-style: none;
  margin: 0;
  /* border-top: 1px solid var(--border-light-grey); */
  border-bottom: 1px solid var(--border-light-grey);

  width: 100%;
}

.userProfile {
  padding: 1rem 0;
}

.closeNavBar {
  display: flex;
  justify-content: space-between;
  padding: 1em 1rem 0 1rem;
}

.collapsedItems li {
  display: grid;
  row-gap: 0.25rem;
  align-content: center;
  justify-items: center;
}


.cartCount,.countLogedOut {
  background-color: var(--app-primary);
  color: var(--app-white);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1.25rem;
  min-width: 1.25rem;
  border-radius: 50%;
  font-size: 0.75rem;
  position: absolute;
  top: 0%;
  right: 4rem;
}

.countLogedOut {
  right: 9.5rem;
}

.heartIcon  {
  position: relative;
}

.wishlistCount,.wishlistCountLogedOut {
  background-color: var(--app-primary);
  color: var(--app-white);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1.25rem;
  min-width: 1.25rem;
  border-radius: 50%;
  font-size: 0.75rem;
  position: absolute;
  top: -30%;
  right: 0rem;
}

.countLogedOut {
  right: 9.5rem;
}

li{
  cursor: pointer;
}

@media (min-width:1200px){
  .container{
    justify-content: space-around;
  }
}

@media (max-width: 1200px) {
  .container {
    justify-content: space-between;
  }

  .cartCount,.countLogedOut {
    top: -26%;
    right: -10%;
  }

  .wishlistCount,.wishlistCountLogedOut {
    top: -30%;
    right: -2%;
  }

  .cartBtn{
    position: relative;
  }

  .cartBtnSmall{
    position: relative;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.6), 0 2px 3px rgba(0, 0, 0, 0.08);
  }

  .searchInput{
    width: 18rem;
  }

  .dropdown {
    max-width: 20rem;
  }
}

@media screen and (max-width: 768px) {
  .cartCount,.countLogedOut {
    top: -16%;
    right: -10%;
  }   
}

/* @media screen and (max-width: 769px) {
  .cartCount {
    top: 88.5%;
    right: 2.75rem;
  }   
} */

@media screen and (max-width: 564px) {
  .label{
    font-size: 0.875rem;
  }
}

@media (max-width:576px){
  .searchInput{
    width: 13rem;
  }

  .dropdown {
    max-width: 15rem;
  }
  .main{
    padding: 0.75rem 1rem;
  }
}

@media (max-width: 440px) {
  .searchInput{
    width: 10rem;
  }

  .dropdown {
    max-width: 12rem;
  }

  .navItems{
    gap: 0.5rem;
  }

  .brandLogo {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 0.5rem;
  }
}

@media (max-width: 360px) {
  .searchInput{
    width: 7rem;
  }

  .dropdown {
    max-width: 9rem;
  }
}

