.mainContainer {
    border: 1px solid #E4E7E9;
}

.itemCount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.625rem 1.5rem;
}

.itemCount h3 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--app-primary-dark);
}

.itemCount p {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.012em;
    text-align: left;
    color: var(--app-claret);
}

.cartTableWrapper {
    overflow-x: auto; /* Enable horizontal scroll */
    margin-top: 1.5rem;
}

.cartTable {
    width: 100%;
    border-collapse: collapse;
}

.cartTable th, .cartTable td {
    padding: 0.625rem 1.5rem;
    padding-bottom: 1.25rem;
    text-align: left;
    vertical-align: middle;
}

.quantity {
    padding: 0.25rem 0.5rem !important;
    padding-bottom: 0rem !important;
    text-align: left !important;
    vertical-align: top !important;
}

.cartTable thead {
    background: #EFF1D9;
    color: #475156;
}

.cartTable th {
    white-space: nowrap; /* Prevent wrapping */
}

.cartTable td {
     /* Prevent wrapping */
    
}

.backBtn {
    display: flex;
    align-items: center;
    color: var(--app-claret);
    gap: 0.5rem;
}

.backBtn p {
    padding: 1.5rem;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.012em;
    text-align: left;
    color: var(--app-claret);
}

.product{
    display: flex;
    gap: 0.75rem;
}

.productImage{
    width: 118px;
    height: 88px;
    border-radius: 8px;
}
 
.productImage img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}

.productDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.productDetails h2{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgb(25, 28, 31);
    margin-bottom: 0;

    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 40px;
    max-width: 500px;
}

.productDetails p{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #6C7275;
}

.qtyController{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #E4E7E9;
    padding: 0.5rem 0rem;
    margin-top: 1rem;
}

.qtyController h2{
    margin-bottom: 0;
    font-family: Public Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.qtyController p{
    font-size: 1.25rem;
}

.qtyController p:hover{
    cursor: pointer;
}

.price p,.subTotal p, .customization p{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #191C1F;
}

.customization {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-top: 1.25rem;
}

.customization h3{
    margin: 0;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--app-claret);
}

.addressColumn {
    position: relative;
}

.addressDropdown {
    width: 100%;
    padding: 0.375rem;
    border: 1px solid #E4E7E9;
    border-radius: 4px;
    font-family: Fraunces;
    font-size: 14px;
    color: #191C1F;
    cursor: pointer;
}

.deleteIcon{
    cursor: pointer;
}

.additionalOptions {

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    width: 100%;
}

.additionalOptions p{
    font-family: Fraunces;
    font-size: 12px;
    color: #6C7275;
}

.additionalOptions :first-child{
    margin: 0;
    color: var(--app-primary);
    text-align: left;
    font-family: Fraunces;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    cursor:pointer;
}


.editAddress {
    color: var(--app-dark);
    cursor: pointer;
    text-align: left;
    font-family: Fraunces;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    cursor:pointer;
}

.shippingSpeedOption{
    display: flex;
    gap: 1.5rem;
    margin-top: 1rem;
}


.shippingOption{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.shippingOption input[type="radio"] {
    accent-color: var(--app-primary);
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.shippingOption label{
    font-family: Fraunces;
    font-size: 14px;
    color: var(--app-dark);
    margin: 0;
    cursor: pointer;
}

.addOn{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--app-grey);
}

.addOn .options{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.addOn .options > div{
    min-width: 30%;
    border: 1px solid var(--app-grey);
}

.addOnHeader,.shippingSpeed h4{
    margin: 0;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #141718;
}

@media screen and (max-width: 1024px) {
    .addOn .options > div{
        display: flex;
        gap: 1rem;
    }

    .productDetails {
        align-items: flex-start;
        text-align: center;
        gap: 0.225rem;
    }

     /* Quantity and Address cells */
     .quantity,
     .addressColumn {
         flex: 0 0 calc(50% - 1rem);
         margin: 0.5rem 0;
     }
}

/* Existing styles remain the same until the media query */

@media screen and (max-width: 768px) {
    .cartTable, 
    .cartTable tbody,
    .cartTable tr,
    .cartTable td {
        display: block;
        width: 100%;
    }

    .cartTable thead {
        display: none;
    }

    /* Product Row */
    .cartTable tr {
        padding: 0rem 0.5rem;
        border-bottom: 1px solid #E4E7E9;
        margin-bottom: 1rem;
    }

    /* Product cell - full width */
    .cartTable td:first-child {
        width: 100%;
    }

    /* Create grid container for quantity and address */
    .cartTable tr:not(:nth-child(3n)) {
        display: flex;
        flex-wrap: wrap;
    }

    /* Product details */
    .product {
        flex-direction: column;
        align-items: flex-start;
        text-align: center;
    }

    .productImage {
        width: 180px;
        height: 140px;
        margin-bottom: 1rem;
    }

    /* Quantity controller */
    .qtyController {
        max-width: none;
        margin: 0 auto;
    }

    /* Address dropdown */
    .addressDropdown {
        width: 100%;
    }

    /* Delete button */
    .deleteItem {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    /* Shipping speed section */
    .shippingSpeed {
        padding: 0rem 0.5rem;
        background-color: #f8f9fa;
        margin: 0.5rem 0;
    }

    .shippingSpeedOption {
        flex-direction: column;
        gap: 0.75rem;
        margin-top: 0.5rem;
    }

    .shippingOption {
        margin: 0;
    }

    .shippingOption label {
        flex: 1;
    }

    /* Add-on section */
    .addOn {
        padding: 0rem 0.5rem;
        margin: 0;
        border-bottom: 1px solid #E4E7E9;
    }

    .options {
        flex-direction: column;
        gap: 1rem;
    }

    .options > div {
        width: 100% !important;
        min-width: 0 !important;
    }

    /* Additional options */
    .additionalOptions {
        margin-top: 0.5rem;
    }

    /* Warning modal */
    .warningModal {
        padding: 1rem 0.5rem;
    }

    .warningModal h2 {
        font-size: 1.2rem;
    }

    .actions {
        padding: 1rem 0.5rem;
    }

    .btnClass {
        width: 100%;
    }

    .warningModal h2 {
        font-size: 1.25rem;
      }
    
      .warningModal p {
        font-size: 0.9rem;
      }
  
      .actions {
          flex-direction: column;
          gap: 0.75rem;
        }
}

@media screen and  (max-width: 586px) {
    .options{
        flex-wrap: wrap;
    }

    .shippingOption label{
        min-width: 11rem;
    }

    .shippingSpeedOption{
        flex-wrap: wrap;
    }

    .cartTable th, .cartTable td {
        padding: 0.625rem 0.5rem;
    }

    .productImage{
        width: 12rem;
    }

    .addressColumn > select{
        width: 12rem;
    }

    .qtyController, .deleteItem {
        margin: 0rem;
        width: 10rem;
    }

    .deleteIcon {
        margin: 0 1rem;
    }
}

.warningModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    color: #333;
  }
  
  .warningModal h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #e74c3c; /* Warning red color */
    margin-bottom: 0.5rem;
  }
  
  .warningModal p {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
    margin-bottom: 1rem;
  }
  
  .actions {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    padding-top: 0.5rem;
  }
  
  .btnClass {
    width: 100%;
    max-width: 200px;
  }
