.crumb {
    color: var(--app-black) !important;
}

.mainContainer {
    padding: 0 var(--panel-padding);
    padding-top: 2rem;
}

.productDetails {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 1.25fr;
    gap: 4.125rem;
}

.imageSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 1.25rem;
}

.mainImage {
    width: 100%;
    max-width: 29.25rem;
    height: 100%;
    max-height: 33.25rem;
    
}

.mainImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.mainImage {
    position: relative;
  }
  
  .arrowRight,
  .arrowLeft {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #25292C;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
  }
  
  .arrowRight {
    right: -1rem; /* Align to the right edge of the main image */
  }
  
  .arrowLeft {
    left: -1rem; /* Align to the left edge of the main image */
  }
  
  .arrowRight:hover,
  .arrowLeft:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .wishlistHeart {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--app-white);
    border-radius: 50%;
    color: var(--app-primary);
    border: 1px solid var(--app-grey);
  }

/* 
.arrowLeft{
    position:relative;
    top: 70%;
    left: 6%;
}

.arrowRight {
    position: relative;
    top: 70%;
    right: 64%;
} */

.images {
    display: flex;
    gap: 2.125rem;
    flex-wrap: wrap;
}

.images img {
    height: 5.625rem;
    width: 5rem;
    object-fit: contain;
    cursor: pointer;
}

.activeImage {
    border: 2px solid #C03760;
    border-radius: 0.5rem;
}

.details {
    display: flex;
    flex-direction: column;
}

.details h1 {
    margin: 0;
    font-family: Butler;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    text-align: left;
}

.details h2 {
    font-family: Fraunces;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: var(--app-grey-text);
}

.details h3 {
    font-family: Butler;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
    text-align: left;
    margin-top: 0.25rem;
}

.authors{
    display: flex !important;
    gap: 0.5rem;
}

.leatherTypes, .varientTypes {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--app-grey);
}

.leatherHeader, .varientHeader {
    display: flex;
    justify-content: space-between;
}

.leatherHeader p, .varientHeader p {
    color: var(--app-claret);
    text-decoration: underline;
    font-family: Fraunces;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.2;
}

.leatherHeader h2, .varientHeader h2 {
    font-family: Butler;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    color: var(--app-black);
}

.leatherTypeBtn, .varientTypeBtn {
    margin-top: 0.75rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.leatherBtn, .varientBtn {
    border: 1px solid var(--app-grey);
    border-radius: 100px;
    padding: 0.625rem 0.875rem;
    font-family: Fraunces;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
}

.activeleatherBtn{
    background: var(--app-grey);
    color: var(--app-white);
}

.activeleatherBtn p{
    color: var(--app-white);
}

.selectColor {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.125rem;
}

.selectColor h2 {
    font-family: Butler;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    color: var(--app-black);
}

.colors {
    width: calc((100vw - 64px - 4.125rem ) / 2.25 * 1.25);
    margin-top: 1rem;
    display: flex;
    gap: 1.25rem;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.colorPicker {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.colorPicker p{
    margin-top: 0.25rem;
    text-align: left;
    max-width: 5rem;
}

.colorPicker img{
    width: 3rem;
    height: 3rem;
    object-fit: fill;
    border-radius: 50%;
}

.active {
    /* border: 2px solid var(--app-grey); */
}

.active img{
    padding: 0.125rem;
    border: 2px solid var(--app-grey);
}

.quantityContainer {
    margin-top: 2rem;
}

.quantityContainer h2 {
    font-family: Butler;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    color: var(--app-black);
}

.quantity {
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
    flex-wrap: wrap;
}

.selectQty {
    display: flex;
    gap: 1.5rem;
    border: 1px solid var(--app-claret);
    border-radius: 0.625rem;
    padding: 1rem 0.625rem;
    width: 130px;
}

.selectQty h2 {
    font-family: Fraunces;
    font-size: 1.1875rem;
    font-weight: 400;
    line-height: 1.2;
    text-align: left;
    color: var(--app-black);
    margin-bottom: 0;
}

.qtyButton {
    width: 27px;
    border-radius: 50%;
    background: var(--app-light-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.qtyButton p {
    margin: 0;
    padding: 0;
    font-family: Butler;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
}

.addToCart {
    max-width: 200px;
    padding: 20px 53px;
    border-radius: 15px;
}

.customizeBtn {
    border: 1px solid var(--app-claret);
    border-radius: 15px;
    padding: 20px 36px;
    cursor: pointer;
}

.customizeBtn button {
    margin: 0;
    padding: 0;
    color: var(--app-claret);
    font-family: Fraunces;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
}

.wishListBtn {
    padding: 20px 36px;
    max-width: 200px;
    border-radius: 1.125rem;
}

.bindingFeatures{
    width: 100%;
    padding: 2rem 0;
    margin-top: 3rem;
    margin-bottom: 0;
}

.bindingFeatures p {
    padding-left: 1rem;
    padding-bottom: 1rem;
}

.featureSection {
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: auto;
    gap: 1rem;
}

.featuresHeading{
    font-family: Butler;
    font-size: 21px;
    font-weight: 500;
    line-height: 25.2px;
    text-align: left;
    color: black;
    padding:1rem;
}

.mainBody {
    margin-top: 1rem;
    background: #EFF1D9;
    border-radius: 12px;
    padding: 48px;
    padding-right: 20px;
}

.tabs {
    
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    white-space: nowrap;
    margin-bottom: 20px;
}

.tab {
    padding: 0.75rem 1rem;
    cursor: pointer;
    background-color: #ffffff;
    transition: background-color 0.3s ease;
    border-radius: 6.25rem;
    white-space: nowrap;
    border: 1px solid #6D8A91;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;

}

.tab:hover {
    background-color: #ccc;
}

.tab.activeTab {
    background-color: #6D8A91; 
    border: none;
}

.tab.activeTab p{
    color: #fff;
}


.tabs::-webkit-scrollbar {
    display: none;
}


.recommendation{
    margin-top: 6.25rem;
}

@media (max-width: 1024px) {
    .productDetails {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .imageSection{
        align-items: center;
    }

    .details h1 {
        font-size: 2rem;
    }

    .details h3 {
        font-size: 1.25rem;
    }

    .mainBody {
        padding: 24px;
        margin-top: 2rem;
    }

    .mainImage {
        width: 100%;
        max-width: 100%;
    }
    .tabs{
        width: 50%;
        margin-bottom: 1rem;
    }
    .colors{
        width: calc(100vw - 132px);
    }

    .bindingFeatures{
        width: 100%;
        padding: 1rem 0;
        margin-top: 1.5rem;
        margin-bottom: 0;
    }

    .recommendation{
        margin-top: 2.5rem;
    }
}

@media (max-width: 768px) {
    .mainContainer {
        padding: 1rem;
    }

    .selectQty {
        width: 150px;
        padding: 1rem;
    }

    .addToCart {
        padding: 10px 20px;
    }

    .customizeBtn {
        padding: 10px 20px;
    }

    .mainBody {
        padding: 16px;
    }

    .tabs{
        width: 50%;
    }

    .images {
        gap: 2.125rem;
    }
    
    .images img {
        height: 4.625rem;
        width: 4rem;
        object-fit: contain;
        cursor: pointer;
    }

    .qtyButton{
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    .qtyButton p{
        font-size: 16px;
    }
    .colors{
        width: calc(100vw - 32px);
    }
}

@media (max-width: 600px) {
    .productDetails {
        flex-direction: column;
    }

    .imageSection {
        align-items: center;
    }

    .selectQty {
        width: 120px;
        padding: 10px;
    }

    .details h1 {
        font-size: 1.5rem;
        line-height: 2.125rem;
    }

    .addToCart {
        padding: 8px 16px;
    }

    .customizeBtn {
        width: 12.5rem;
        padding: 0.5rem 2rem;
    }

    .quantity {
        flex-direction: column;
        gap: 1rem;
    }

    .selectQty {
        flex-direction: row;
        justify-content: space-between;
        width: 100px;
    }

    .selectQty h2 {
        font-size: 1rem;
    }

    .tabs{
        width: 60%;
    }
}

@media (max-width:480px){
    .tabs{
        width: 90%;
    }

    .images {
        gap: 2.125rem;
    }
    
    .images img {
        height: 3.625rem;
        width: 3rem;
        object-fit: contain;
        cursor: pointer;
    }
}

@media (max-width:320px){
    .tabs{
        width: 100%;
    }

    .images{
        gap: 1rem;
    }
}
