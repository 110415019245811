.colors {
    margin-top: 12px;
    display: flex;
    gap: 0.75rem;
    flex-wrap: wrap;
}

.colorPicker {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 2px solid var(--app-grey);
    cursor: pointer;
    position: relative;
}

.colorPicker.hasPrice {
    margin-right: 15px;
}

.selected {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 2px solid var(--app-black);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.selected.hasPrice {
    margin-right: 15px;
}

.active {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid var(--app-white);
}

.colorPickerDropdown h3 {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.001em;
    text-align: left;
    color: #5F6D7E;
}

.detail {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    background: var(--app-light-pink);
    padding: 10px;
    margin-bottom: 1.5rem;
}

.Iicon {
    color: var(--app-claret);
}

.detail p {
    font-family: Fraunces, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.dollarIcon {
    position: absolute;
    right: -10px;
    bottom: -8px;
    background-color: #FCC418;
    border-radius: 4px;
    min-width: 60px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    z-index: 5;
}

.dollarIcon span {
    font-size: 9px;
    font-weight: 600;
    color: #000;
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80px;
    line-height: 1;
    letter-spacing: -0.2px;
}