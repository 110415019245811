.mainContainer {
    border: 1px solid #E4E7E9;
}

.itemCount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.625rem 1.5rem;
}

.itemCount h3 {
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--app-primary-dark);
}

.itemCount p {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.012em;
    text-align: left;
    color: var(--app-claret);
}

.cartTableWrapper {
    overflow-x: auto; /* Enable horizontal scroll */
}

.cartTable {
    width: 100%;
    border-collapse: collapse;
}

.cartTable th, .cartTable td {
    padding: 0.625rem 1.5rem;
    text-align: left;
    vertical-align: middle;
}

.cartTable thead {
    background: #EFF1D9;
    color: #475156;
}

.cartTable th {
    white-space: nowrap; /* Prevent wrapping */
}

.cartTable td {
     /* Prevent wrapping */
    
}

.backBtn {
    display: flex;
    align-items: center;
    color: var(--app-claret);
    gap: 0.5rem;
}

.backBtn p {
    padding: 1.5rem;
    padding-right: 0;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.012em;
    text-align: left;
    color: var(--app-claret);
}

.backBtn .leftArrow,.backBtn p{
    cursor: pointer;
}

.deleteIcon{
    cursor: pointer;
}

.product{
    display: flex;
    gap: 0.75rem;
}

.productImage{
    width: 88px;
    height: 68px;
    border-radius: 8px;
}
 
.productImage img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}

.productDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.productDetails h2{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgb(25, 28, 31);
    margin-bottom: 0;

    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 40px;
    max-width: 500px;
}

.productDetails p{
    font-family: Fraunces;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #6C7275;
}

.qtyController{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #E4E7E9;
    padding: 0.5rem 0rem;
}

.qtyController h2{
    margin-bottom: 0;
    font-family: Public Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.qtyController p{
    font-size: 1.25rem;
    cursor: pointer;
}


.price p,.subTotal p, .customization p{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #191C1F;
    text-wrap: nowrap;
}

.customization h3{
    margin: 0;
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--app-claret);
}
.customization div{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.customization h2{
    cursor: pointer;
}
.unavailableRow {
    opacity: 0.6;
}

.unavailableRow .productImage {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8d7da; /* Light red background for unavailable items */
    border: 1px solid #f5c6cb;
    padding: 10px;
}

.unavailableText {
    color: #721c24; /* Dark red for the text */
    font-weight: bold;
    font-size: 0.9rem;
    text-align: center;
}

.unavailableRow td {
    text-align: center;
    color: #6c757d; /* Grey text for unavailable rows */
}

.disabled {
    pointer-events: none;
}

.customizationDetails{
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--app-claret);
    text-decoration: underline;
}

.cartTable {
    width: 100%;
    border-collapse: collapse;
}

.cartTable th, .cartTable td {
    padding: 0.625rem 1.5rem;
    text-align: left;
    vertical-align: middle;
}

.cartTable thead {
    background: #EFF1D9;
    color: #475156;
}

.product {
    display: flex;
    gap: 0.75rem;
}

.productImage {
    width: 88px;
    height: 68px;
    border-radius: 8px;
}

.productImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}

.productDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.productDetails p {
    font-family: Fraunces;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #6C7275;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {

    .itemCount {
        padding: 0.625rem 1.5rem;
    }

    .cartTable, .cartTable tbody, .cartTable tr, .cartTable td {
        display: block;
        width: 100%;
    }

    .cartTable thead {
        display: none; /* Hide table headers on mobile */
    }

    .cartTable tr {
        margin-bottom: 1rem;
        border-bottom: 1px solid #E4E7E9;
        padding: 1rem 0;
    }

    /* Product cell - full width */
    .cartTable td:first-child {
        width: 100%;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
    }

    /* Create grid container for non-product cells */
    .cartTable tr {
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    /* Style for all cells except product */
    .cartTable td:not(:first-child) {
        flex: 0 0 50%; /* Two items per row */
        padding: 0.75rem 1rem;
        box-sizing: border-box;
    }

    /* Container for label and content */
    .cartTable td:not(:first-child)::before {
        content: attr(data-label);
        font-weight: 600;
        color: #475156;
        display: block;
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
    }

    /* Product specific styles */
    .product {
        align-items: center;
        width: 90%;
    }

    /* Quantity and Price in one row */
    .quantity,
    .price {
        display: flex;
        flex-direction: column;
    }

    .qtyController {
        max-width: 150px;
    }

    /* Customization and Subtotal in one row */
    .customization,
    .subTotal {
        display: flex;
        flex-direction: column;
    }

    .customization div {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    /* Delete button styling */
    .deleteItem {
        width: fit-content !important;
        text-align: right;
        padding-top: 1rem;
        flex: 0 0 100% !important;
        position: absolute;
        top: 10%;
        right: 2%;
    }

    .deleteItem::before {
        content: none !important;
    }

    .backBtn p {
        padding: 1rem;
        padding-right: 0;
        font-family: Fraunces;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: var(--app-claret);
    }
}