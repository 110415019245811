.linkReset {
  text-decoration: none; /* Remove default underline */
  color: inherit; /* Keep text color the same */
  display: block; /* Behave like a div */
  width: 100%;
}

.linkReset:hover,
.linkReset:focus {
  text-decoration: none;
  color: inherit;
}

.cardContainer {
  width: 100%;
  max-width: 21.875rem;
  height: fit-content;
  background: #f6f6f6;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 1.5rem 1.675rem;
  cursor: pointer;
}

.imageContainer {
  position: relative;
  /* padding-top: 56.25%; 16:9 Aspect Ratio */
  height: 15rem;
  overflow: hidden;
  border-radius: 0.75rem;
}

.image {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;

}

.categoryTag {
  position: absolute;
  top: 8%;
  right: 1%;
  background-color: var(--app-dark-gold);/* Customize the color */
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 600;
}

.cardContent {
  margin-top: 1.5rem;
}

.date {
  color: #888;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.title {
  color: #110D06;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.authorSection {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.authorImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.authorName {
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.authorNameDetails{
  display: flex;
  flex-direction: column;
  column-gap: 4px;
  align-items: flex-start;
  justify-content: center;
}

.authorNameDetails h2{
  margin: 0;
  padding: 0;
  font-family: Fraunces;
  font-size: 14px;
  line-height: 17.26px;
}

.authorNameDetails p{
  margin: 0;
  padding: 0;
  color: #666666;
  font-family: Fraunces;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.001em;
  text-align: left;
}

.flag { 
  display: inline-block; 
  background-repeat: no-repeat;
}

.flag.flag-48 { 
    margin-right: 0.325rem;
    display: inline-block; 
    width: 48px;
    height: 48px;
    background-image: url(/public/Assets/Images/Banners/sprite-flags-48x48.png); 
    background-repeat: no-repeat;
 }
.flag.flag-48.flag-abkhazia { background-position: -0px -0px; }
.flag.flag-48.flag-basque-country { background-position: -48px -0px; }
.flag.flag-48.flag-british-antarctic-territory { background-position: -96px -0px; }
.flag.flag-48.flag-commonwealth { background-position: -144px -0px; }
.flag.flag-48.flag-england { background-position: -192px -0px; }
.flag.flag-48.flag-gosquared { background-position: -240px -0px; }
.flag.flag-48.flag-kosovo { background-position: -288px -0px; }
.flag.flag-48.flag-mars { background-position: -336px -0px; }
.flag.flag-48.flag-nagorno-karabakh { background-position: -384px -0px; }
.flag.flag-48.flag-nato { background-position: -432px -0px; }
.flag.flag-48.flag-northern-cyprus { background-position: -480px -0px; }
.flag.flag-48.flag-olympics { background-position: -528px -0px; }
.flag.flag-48.flag-red-cross { background-position: -576px -0px; }
.flag.flag-48.flag-scotland { background-position: -624px -0px; }
.flag.flag-48.flag-somaliland { background-position: -672px -0px; }
.flag.flag-48.flag-south-ossetia { background-position: -720px -0px; }
.flag.flag-48.flag-united-nations { background-position: -768px -0px; }
.flag.flag-48.flag-unknown { background-position: -0px -48px; }
.flag.flag-48.flag-wales { background-position: -48px -48px; }
.flag.flag-48.flag-ad { background-position: -96px -48px; }
.flag.flag-48.flag-ae { background-position: -144px -48px; }
.flag.flag-48.flag-af { background-position: -192px -48px; }
.flag.flag-48.flag-ag { background-position: -240px -48px; }
.flag.flag-48.flag-ai { background-position: -288px -48px; }
.flag.flag-48.flag-al { background-position: -336px -48px; }
.flag.flag-48.flag-am { background-position: -384px -48px; }
.flag.flag-48.flag-an { background-position: -432px -48px; }
.flag.flag-48.flag-ao { background-position: -480px -48px; }
.flag.flag-48.flag-aq { background-position: -528px -48px; }
.flag.flag-48.flag-ar { background-position: -576px -48px; }
.flag.flag-48.flag-as { background-position: -624px -48px; }
.flag.flag-48.flag-at { background-position: -672px -48px; }
.flag.flag-48.flag-au { background-position: -720px -48px; }
.flag.flag-48.flag-aw { background-position: -768px -48px; }
.flag.flag-48.flag-ax { background-position: -0px -96px; }
.flag.flag-48.flag-az { background-position: -48px -96px; }
.flag.flag-48.flag-ba { background-position: -96px -96px; }
.flag.flag-48.flag-bb { background-position: -144px -96px; }
.flag.flag-48.flag-bd { background-position: -192px -96px; }
.flag.flag-48.flag-be { background-position: -240px -96px; }
.flag.flag-48.flag-bf { background-position: -288px -96px; }
.flag.flag-48.flag-bg { background-position: -336px -96px; }
.flag.flag-48.flag-bh { background-position: -384px -96px; }
.flag.flag-48.flag-bi { background-position: -432px -96px; }
.flag.flag-48.flag-bj { background-position: -480px -96px; }
.flag.flag-48.flag-bl { background-position: -528px -96px; }
.flag.flag-48.flag-bm { background-position: -576px -96px; }
.flag.flag-48.flag-bn { background-position: -624px -96px; }
.flag.flag-48.flag-bo { background-position: -672px -96px; }
.flag.flag-48.flag-br { background-position: -720px -96px; }
.flag.flag-48.flag-bs { background-position: -768px -96px; }
.flag.flag-48.flag-bt { background-position: -0px -144px; }
.flag.flag-48.flag-bw { background-position: -48px -144px; }
.flag.flag-48.flag-by { background-position: -96px -144px; }
.flag.flag-48.flag-bz { background-position: -144px -144px; }
.flag.flag-48.flag-ca { background-position: -192px -144px; }
.flag.flag-48.flag-cc { background-position: -240px -144px; }
.flag.flag-48.flag-cd { background-position: -288px -144px; }
.flag.flag-48.flag-cf { background-position: -336px -144px; }
.flag.flag-48.flag-cg { background-position: -384px -144px; }
.flag.flag-48.flag-ch { background-position: -432px -144px; }
.flag.flag-48.flag-ci { background-position: -480px -144px; }
.flag.flag-48.flag-ck { background-position: -528px -144px; }
.flag.flag-48.flag-cl { background-position: -576px -144px; }
.flag.flag-48.flag-cm { background-position: -624px -144px; }
.flag.flag-48.flag-cn { background-position: -672px -144px; }
.flag.flag-48.flag-co { background-position: -720px -144px; }
.flag.flag-48.flag-cr { background-position: -768px -144px; }
.flag.flag-48.flag-cu { background-position: -0px -192px; }
.flag.flag-48.flag-cv { background-position: -48px -192px; }
.flag.flag-48.flag-cw { background-position: -96px -192px; }
.flag.flag-48.flag-cx { background-position: -144px -192px; }
.flag.flag-48.flag-cy { background-position: -192px -192px; }
.flag.flag-48.flag-cz { background-position: -240px -192px; }
.flag.flag-48.flag-de { background-position: -288px -192px; }
.flag.flag-48.flag-dj { background-position: -336px -192px; }
.flag.flag-48.flag-dk { background-position: -384px -192px; }
.flag.flag-48.flag-dm { background-position: -432px -192px; }
.flag.flag-48.flag-do { background-position: -480px -192px; }
.flag.flag-48.flag-dz { background-position: -528px -192px; }
.flag.flag-48.flag-ec { background-position: -576px -192px; }
.flag.flag-48.flag-ee { background-position: -624px -192px; }
.flag.flag-48.flag-eg { background-position: -672px -192px; }
.flag.flag-48.flag-eh { background-position: -720px -192px; }
.flag.flag-48.flag-er { background-position: -768px -192px; }
.flag.flag-48.flag-es { background-position: -0px -240px; }
.flag.flag-48.flag-et { background-position: -48px -240px; }
.flag.flag-48.flag-eu { background-position: -96px -240px; }
.flag.flag-48.flag-fi { background-position: -144px -240px; }
.flag.flag-48.flag-fj { background-position: -192px -240px; }
.flag.flag-48.flag-fk { background-position: -240px -240px; }
.flag.flag-48.flag-fm { background-position: -288px -240px; }
.flag.flag-48.flag-fo { background-position: -336px -240px; }
.flag.flag-48.flag-fr { background-position: -384px -240px; }
.flag.flag-48.flag-ga { background-position: -432px -240px; }
.flag.flag-48.flag-gb { background-position: -480px -240px; }
.flag.flag-48.flag-gd { background-position: -528px -240px; }
.flag.flag-48.flag-ge { background-position: -576px -240px; }
.flag.flag-48.flag-gg { background-position: -624px -240px; }
.flag.flag-48.flag-gh { background-position: -672px -240px; }
.flag.flag-48.flag-gi { background-position: -720px -240px; }
.flag.flag-48.flag-gl { background-position: -768px -240px; }
.flag.flag-48.flag-gm { background-position: -0px -288px; }
.flag.flag-48.flag-gn { background-position: -48px -288px; }
.flag.flag-48.flag-gq { background-position: -96px -288px; }
.flag.flag-48.flag-gr { background-position: -144px -288px; }
.flag.flag-48.flag-gs { background-position: -192px -288px; }
.flag.flag-48.flag-gt { background-position: -240px -288px; }
.flag.flag-48.flag-gu { background-position: -288px -288px; }
.flag.flag-48.flag-gw { background-position: -336px -288px; }
.flag.flag-48.flag-gy { background-position: -384px -288px; }
.flag.flag-48.flag-hk { background-position: -432px -288px; }
.flag.flag-48.flag-hn { background-position: -480px -288px; }
.flag.flag-48.flag-hr { background-position: -528px -288px; }
.flag.flag-48.flag-ht { background-position: -576px -288px; }
.flag.flag-48.flag-hu { background-position: -624px -288px; }
.flag.flag-48.flag-ic { background-position: -672px -288px; }
.flag.flag-48.flag-id { background-position: -720px -288px; }
.flag.flag-48.flag-ie { background-position: -768px -288px; }
.flag.flag-48.flag-il { background-position: -0px -336px; }
.flag.flag-48.flag-im { background-position: -48px -336px; }
.flag.flag-48.flag-in { background-position: -96px -336px; }
.flag.flag-48.flag-iq { background-position: -144px -336px; }
.flag.flag-48.flag-ir { background-position: -192px -336px; }
.flag.flag-48.flag-is { background-position: -240px -336px; }
.flag.flag-48.flag-it { background-position: -288px -336px; }
.flag.flag-48.flag-je { background-position: -336px -336px; }
.flag.flag-48.flag-jm { background-position: -384px -336px; }
.flag.flag-48.flag-jo { background-position: -432px -336px; }
.flag.flag-48.flag-jp { background-position: -480px -336px; }
.flag.flag-48.flag-ke { background-position: -528px -336px; }
.flag.flag-48.flag-kg { background-position: -576px -336px; }
.flag.flag-48.flag-kh { background-position: -624px -336px; }
.flag.flag-48.flag-ki { background-position: -672px -336px; }
.flag.flag-48.flag-km { background-position: -720px -336px; }
.flag.flag-48.flag-kn { background-position: -768px -336px; }
.flag.flag-48.flag-kp { background-position: -0px -384px; }
.flag.flag-48.flag-kr { background-position: -48px -384px; }
.flag.flag-48.flag-kw { background-position: -96px -384px; }
.flag.flag-48.flag-ky { background-position: -144px -384px; }
.flag.flag-48.flag-kz { background-position: -192px -384px; }
.flag.flag-48.flag-la { background-position: -240px -384px; }
.flag.flag-48.flag-lb { background-position: -288px -384px; }
.flag.flag-48.flag-lc { background-position: -336px -384px; }
.flag.flag-48.flag-li { background-position: -384px -384px; }
.flag.flag-48.flag-lk { background-position: -432px -384px; }
.flag.flag-48.flag-lr { background-position: -480px -384px; }
.flag.flag-48.flag-ls { background-position: -528px -384px; }
.flag.flag-48.flag-lt { background-position: -576px -384px; }
.flag.flag-48.flag-lu { background-position: -624px -384px; }
.flag.flag-48.flag-lv { background-position: -672px -384px; }
.flag.flag-48.flag-ly { background-position: -720px -384px; }
.flag.flag-48.flag-ma { background-position: -768px -384px; }
.flag.flag-48.flag-mc { background-position: -0px -432px; }
.flag.flag-48.flag-md { background-position: -48px -432px; }
.flag.flag-48.flag-me { background-position: -96px -432px; }
.flag.flag-48.flag-mf { background-position: -144px -432px; }
.flag.flag-48.flag-mg { background-position: -192px -432px; }
.flag.flag-48.flag-mh { background-position: -240px -432px; }
.flag.flag-48.flag-mk { background-position: -288px -432px; }
.flag.flag-48.flag-ml { background-position: -336px -432px; }
.flag.flag-48.flag-mm { background-position: -384px -432px; }
.flag.flag-48.flag-mn { background-position: -432px -432px; }
.flag.flag-48.flag-mo { background-position: -480px -432px; }
.flag.flag-48.flag-mp { background-position: -528px -432px; }
.flag.flag-48.flag-mq { background-position: -576px -432px; }
.flag.flag-48.flag-mr { background-position: -624px -432px; }
.flag.flag-48.flag-ms { background-position: -672px -432px; }
.flag.flag-48.flag-mt { background-position: -720px -432px; }
.flag.flag-48.flag-mu { background-position: -768px -432px; }
.flag.flag-48.flag-mv { background-position: -0px -480px; }
.flag.flag-48.flag-mw { background-position: -48px -480px; }
.flag.flag-48.flag-mx { background-position: -96px -480px; }
.flag.flag-48.flag-my { background-position: -144px -480px; }
.flag.flag-48.flag-mz { background-position: -192px -480px; }
.flag.flag-48.flag-na { background-position: -240px -480px; }
.flag.flag-48.flag-nc { background-position: -288px -480px; }
.flag.flag-48.flag-ne { background-position: -336px -480px; }
.flag.flag-48.flag-nf { background-position: -384px -480px; }
.flag.flag-48.flag-ng { background-position: -432px -480px; }
.flag.flag-48.flag-ni { background-position: -480px -480px; }
.flag.flag-48.flag-nl { background-position: -528px -480px; }
.flag.flag-48.flag-no { background-position: -576px -480px; }
.flag.flag-48.flag-np { background-position: -624px -480px; }
.flag.flag-48.flag-nr { background-position: -672px -480px; }
.flag.flag-48.flag-nu { background-position: -720px -480px; }
.flag.flag-48.flag-nz { background-position: -768px -480px; }
.flag.flag-48.flag-om { background-position: -0px -528px; }
.flag.flag-48.flag-pa { background-position: -48px -528px; }
.flag.flag-48.flag-pe { background-position: -96px -528px; }
.flag.flag-48.flag-pf { background-position: -144px -528px; }
.flag.flag-48.flag-pg { background-position: -192px -528px; }
.flag.flag-48.flag-ph { background-position: -240px -528px; }
.flag.flag-48.flag-pirate-black { background-position: -288px -528px; }
.flag.flag-48.flag-pirate-white { background-position: -336px -528px; }
.flag.flag-48.flag-pk { background-position: -384px -528px; }
.flag.flag-48.flag-pl { background-position: -432px -528px; }
.flag.flag-48.flag-pn { background-position: -480px -528px; }
.flag.flag-48.flag-pr { background-position: -528px -528px; }
.flag.flag-48.flag-ps { background-position: -576px -528px; }
.flag.flag-48.flag-pt { background-position: -624px -528px; }
.flag.flag-48.flag-pw { background-position: -672px -528px; }
.flag.flag-48.flag-py { background-position: -720px -528px; }
.flag.flag-48.flag-qa { background-position: -768px -528px; }
.flag.flag-48.flag-ro { background-position: -0px -576px; }
.flag.flag-48.flag-rs { background-position: -48px -576px; }
.flag.flag-48.flag-ru { background-position: -96px -576px; }
.flag.flag-48.flag-rw { background-position: -144px -576px; }
.flag.flag-48.flag-sa { background-position: -192px -576px; }
.flag.flag-48.flag-sb { background-position: -240px -576px; }
.flag.flag-48.flag-sc { background-position: -288px -576px; }
.flag.flag-48.flag-sd { background-position: -336px -576px; }
.flag.flag-48.flag-se { background-position: -384px -576px; }
.flag.flag-48.flag-sg { background-position: -432px -576px; }
.flag.flag-48.flag-sh { background-position: -480px -576px; }
.flag.flag-48.flag-si { background-position: -528px -576px; }
.flag.flag-48.flag-sk { background-position: -576px -576px; }
.flag.flag-48.flag-sl { background-position: -624px -576px; }
.flag.flag-48.flag-sm { background-position: -672px -576px; }
.flag.flag-48.flag-sn { background-position: -720px -576px; }
.flag.flag-48.flag-so { background-position: -768px -576px; }
.flag.flag-48.flag-sr { background-position: -0px -624px; }
.flag.flag-48.flag-ss { background-position: -48px -624px; }
.flag.flag-48.flag-st { background-position: -96px -624px; }
.flag.flag-48.flag-sv { background-position: -144px -624px; }
.flag.flag-48.flag-sy { background-position: -192px -624px; }
.flag.flag-48.flag-sz { background-position: -240px -624px; }
.flag.flag-48.flag-tc { background-position: -288px -624px; }
.flag.flag-48.flag-td { background-position: -336px -624px; }
.flag.flag-48.flag-tf { background-position: -384px -624px; }
.flag.flag-48.flag-tg { background-position: -432px -624px; }
.flag.flag-48.flag-th { background-position: -480px -624px; }
.flag.flag-48.flag-tj { background-position: -528px -624px; }
.flag.flag-48.flag-tk { background-position: -576px -624px; }
.flag.flag-48.flag-tl { background-position: -624px -624px; }
.flag.flag-48.flag-tm { background-position: -672px -624px; }
.flag.flag-48.flag-tn { background-position: -720px -624px; }
.flag.flag-48.flag-to { background-position: -768px -624px; }
.flag.flag-48.flag-tr { background-position: -0px -672px; }
.flag.flag-48.flag-tt { background-position: -48px -672px; }
.flag.flag-48.flag-tv { background-position: -96px -672px; }
.flag.flag-48.flag-tw { background-position: -144px -672px; }
.flag.flag-48.flag-tz { background-position: -192px -672px; }
.flag.flag-48.flag-ua { background-position: -240px -672px; }
.flag.flag-48.flag-ug { background-position: -288px -672px; }
.flag.flag-48.flag-us { background-position: -336px -672px; }
.flag.flag-48.flag-uy { background-position: -384px -672px; }
.flag.flag-48.flag-uz { background-position: -432px -672px; }
.flag.flag-48.flag-va { background-position: -480px -672px; }
.flag.flag-48.flag-vc { background-position: -528px -672px; }
.flag.flag-48.flag-ve { background-position: -576px -672px; }
.flag.flag-48.flag-vg { background-position: -624px -672px; }
.flag.flag-48.flag-vi { background-position: -672px -672px; }
.flag.flag-48.flag-vn { background-position: -720px -672px; }
.flag.flag-48.flag-vu { background-position: -768px -672px; }



@media(max-width:1024px){
  .title{
    font-size: 1rem;
  }
}



@media (max-width: 768px) {
  .cardContainer {
    width: 40vw;
    height: 25rem;
  }

  .imageContainer {
    height: 60%;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  img.flag{
    width:30px
  }

  .categoryTag {
    font-size: 0.7rem;
    padding: 4px 8px;
  }

  .date, .authorName {
    font-size: 0.875rem;
  }

  .title {
    font-size: 1rem;
  }

  .authorImage {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .cardContainer {
    max-width: 100%;
    padding-left: 1rem;
    height: 20rem;
  }

  .imageContainer {
    width: 100%;
  }

  .image {
    height: 100%;
    object-fit: cover;
  }

  .categoryTag {
    font-size: 0.75rem;
    padding: 3px 6px;
  }

  .date, .authorName {
    font-size: 0.75rem;
  }

  .title {
    font-size: 0.875rem;
  }

  .authorImage {
    width: 25px;
    height: 25px;
  }
}
