.mainContainer {}

.nav {
    display: flex;
    padding: 23px 32px;
    justify-content: space-around;
    gap: 3rem;
    background: var(--app-bianca);
}

.headerText {
    display: flex;
}

.headerText h2,
.trending h2,
.searchResult h2 {
    font-family: Fraunces;
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.searchResult h2>span {
    margin-left: 0.75rem;
}

.searchCount {
    color: #5D6568;
    font-family: Fraunces;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #D0D4D7;
}


.headerText h2 {
    color: black;
    font-size: 24px;
    font-weight: 500;
    padding-top: 8px;
    padding-right: 24px;
    padding-bottom: 0px;
}

.headerText p {
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    color: var(--app-white);
}

.SearchComponent {
    display: flex;
    justify-content: center;
    gap: 1.25rem;
    flex: 1;
}

.sortBtn {
    background: var(--app-white);
    padding: 0 1.125rem;
    border: 1px solid var(--app-grey);
    border-radius: 6.25rem;
    display: flex;
    align-items: center;
}

.sortBtn select {
    color: var(--app-claret);
    font-family: Fraunces;
    font-size: 14px;
    font-weight: 400;
    border: none;
    padding: 10px 30px 10px 15px;
    cursor: pointer;
    background-color: var(--app-white);
}

.sortBtn select:focus{
    outline: none;
}

.searchWithin{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--radi-mlg, 8px);
    border: 1px solid #D0D4D7;
    background: #FFF;
    padding: 0.75rem 1.5rem;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
}

.searchWithin input{
    border: none;
}

.searchIconWithin{
    color: var(--app-primary);
}

.mainSection {
    width: 100vw;
    display: flex;
}


.content {
    width: 90%;
    margin: 20px 40px;
}


.carousel {
    width: 90%;
}

.cardGrid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.trending {
    margin-top: -5rem;
    width: 90%;
}

.search {
    margin: 3rem 0;
    width: 90%;
}

.bestSeller {
    width: 90%;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.bestAuthor {
    background: var(--app-light-grey);
    width: 85%;
    border-radius: 0.5rem;
}

.paginationControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-top: 3rem;
    font-family: Arial, sans-serif;
    width: 95%;
}

.paginationControls button {
    color: #110D06;
    font-family: Fraunces;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.014px;
}

.paginatonBtn{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.arrowLeft,.arrowRight{
    cursor: pointer;
}

.paginationControls button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.paginationControls span {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.paginationControls span a,
.paginationControls span .pageNumber {
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.paginationControls span .pageNumber.active {
    background-color: #971E42; /* Highlighted color */
    color: #fff;
    font-weight: bold;
}

.paginationControls span a:hover {
    background-color: #ddd;
}

.paginationControls .dots {
    display: inline-block;
    padding: 0.5rem 0.75rem;
    color: #333;
    font-size: 1rem;
}

/* Responsive styles */
@media (max-width: 1200px) {


    .cardGrid {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }

    .searchResult .cardGrid {
        grid-template-columns: 1fr 1fr 1fr;
    }


    .trending,
    .bestSeller,
    .bestAuthor {
        margin: 0 1rem;
        padding: 20px;
    }

    .bestAuthor {
        width: 90%;
    }
}


@media (max-width:1024px) {
    .nav {
        flex-direction: column;
        padding: 10px 0;
        text-align: center;
        gap: 1rem;
    }

    .headerText {
        flex-direction: column;
        align-items: center;
    }

    .headerText h2 {
        border: none;
        padding: 0;
        margin-bottom: 0.625rem;
    }

    .headerText p {
        padding: 0;
        margin-top: 5px;
    }

    .SearchComponent {
        margin:0.5rem 0;
    }

    .sortBtn {
        justify-content: center;
    }

    .sortBtn select {
        color: var(--app-claret);
        font-family: Fraunces;
        font-size: 14px;
        font-weight: 400;
        border: none;
        padding: 6px 15px 6px 15px;
        cursor: pointer;
    }

}

@media (max-width: 768px) {
    .nav {
        flex-direction: column;
        padding: 10px 0;
        text-align: center;
        gap: 1rem;
    }

    .headerText {
        flex-direction: column;
        align-items: center;
    }

    .headerText h2 {
        border: none;
        padding: 0;
        margin-top: 1rem;
        margin-bottom: 0px;
    }

    .headerText p {
        padding: 0;
        margin-top: 5px;
    }

    .SearchComponent {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }

    .sortBtn {
        justify-content: center;
    }

    .mainSection {
        flex-direction: column;
    }

    .content {
        width: 100%;
        margin-left: 1rem;
    }

    .trending,
    .bestSeller,
    .bestAuthor {
        margin: 0 0.5rem;
        padding: 20px;
    }

    .bestAuthor {
        margin-top: 50px;
    }

     .cardGrid {
        grid-template-columns: 1fr 1fr;
        width: 90%;
    }
    .paginationControls{
        width: 90%;
        align-items: center;
        justify-content: center;
    }
    .paginatonBtn{
        display: none;
    }

    .sortBtn select {
       max-width: 8rem;
    }

    .sortByContainer {
        margin-right: 1.5rem;
    }
}

@media (max-width: 576px){
    .SearchComponent{
        align-items: end;
    }
    .sortBtn{
        margin-right: 20px;
    }
}
@media (max-width: 480px) {
    .nav {
        padding: 5px 0;
        gap: 1rem;
    }

    .headerText h2 {
        font-size: 1.5rem;
    }

    .headerText p {
        font-size: 12px;
    }

    .SearchComponent {
        gap: 0.5rem;
    }

    .sortBtn {
        padding: 0 0.5rem;
    }

    .sortBtn select {
        padding: 2px 4px;
    }

    .trending h2 {
        font-size: 1.5rem;
    }

    .cardGrid {
        gap: 10px;
    }

    .trending,
    .bestSeller,
    .bestAuthor {
        margin: 0;
        padding: 10px;
    }

    .bestAuthor {
        width: 90%;
        margin: 20px 0 0;
    }

    .cardGrid {
        width: 90%;
    }
}

@media (max-width : 375px) {
    .content {
        width: 100%;
        margin: 12px 1rem;
        margin-left: 1.125rem;
    }
}