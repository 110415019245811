.mainContainer {
  width: 100%;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #a4a1aa33;
}

.headerSection {
  background: var(--app-light-grey);
  padding: 1.5rem 1.25rem;
  display: flex;
  gap: 1.5rem;
  border-radius: 12px;
  justify-content: space-around;
}

.invoice {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerItem h2 {
  color: var(--Gray-50, #5f6d7e);
  font-family: Fraunces;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.012px;
}

.headerItem p {
  color: var(--app-dark);
  font-family: Fraunces;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.014px;
}

.orderDetails {
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.orderDetails h2 {
  color: var(--app-dark);
  font-family: Fraunces;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.payment {
  padding: 10px;
  background: #ffd4d4;
  color: #d21c1c;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Fraunces;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 10px;
}

.transit,
.completed {
  border-radius: var(--radi-md, 4px);
  background: rgba(227, 178, 49, 0.1);
  padding: 6.5px 10px;
  width: fit-content;
}

.completed {
  background: #3cd1391a;
}

.transit p,
.completed p {
  color: #e3b231;
  font-family: Butler;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.completed p {
  color: #3cd139;
}

.orderItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.orderItem p {
  font-family: Fraunces;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--app-dark);
}

.bookDetails {
  display: flex;
  gap: 0.75rem;
}

.bookImage {
  width: 5rem;
  height: 5.375rem;
  border-radius: 10px;
}

.bookImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.bookData {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bookData h2 {
  color: var(--app-dark);
  font-family: Butler;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 20rem;

  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-height: 40px;
  max-width: 500px;
}

.bookData h3,
.bookData p {
  color: var(--app-dark);
  font-family: Fraunces;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.writeReview {
  margin-top: 1rem;
  color: var(--app-dark);
  font-family: Fraunces;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.bookDetailsContainer {
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;
}

.orderAddress {
  max-width: 20rem;
}

.address {
  font-size: 0.875rem;
  font-weight: 400;
}

.price {
  text-wrap: nowrap;
}

.status {
  margin: 0.75rem 0;
}

.itemContainer {
  margin-top: 1rem;
}

.actions {
  min-width: 6rem;
}

.subOrder {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 820px) {
  .orderItem {
    flex-wrap: wrap;
    width: 100%;
  }

  .btnContainer {
    flex-direction: row;
    margin-top: 1rem;
  }

  .itemContainer {
    padding-bottom: 1rem;
  }

  .bookDetailsContainer {
    width: 100%;
  }

  .btnContainer > button {
    font-size: 0.875rem;
    padding: 0.5rem;
  }

  .headerSection {
    flex-wrap: wrap;
    padding: 0.5rem 0.5rem;
    gap: 0.875rem;
    justify-content: flex-start;
  }

  .trackingName {
    font-size: 0.75rem;
  }

  .trackingName > span {
    font-weight: bold;
  }

  .subOrder {
    gap: 0.75rem;
  }

  .status {
    text-wrap: nowrap;
  }
}

@media screen and (max-width: 600px) {
  .orderItem {
    flex-wrap: wrap;
    width: 100%;
  }

  .btnContainer {
    flex-direction: row;
    margin-top: 1rem;
  }

  .itemContainer {
    padding-bottom: 1rem;
  }

  .bookDetailsContainer {
    width: 100%;
  }

  .btnContainer > button {
    font-size: 0.875rem;
    padding: 0.5rem;
  }

  .headerSection {
    flex-wrap: wrap;
    padding: 0.5rem 0.5rem;
    gap: 0.875rem;
    justify-content: flex-start;
  }

  .trackingName {
    font-size: 0.75rem;
  }

  .trackingName > span {
    font-weight: bold;
  }

  .subOrder {
    gap: 0.75rem;
  }

  .status {
    text-wrap: nowrap;
  }
  
  .bookImage img{
    object-fit: cover;
  }
}

.bookDetailsContainerDispute{
  width: 100%;
}

.orderItemDispute{
  width: 100%;
}

.bookDetailsDispute{
width: 30%;
}

.additionalMessage{
  width: 69%;
  text-wrap: wrap;
  text-align: center;
  max-height: 200px;
  overflow: auto;
  font-weight: normal !important;
}


@media (max-width:480px){
  .bookDetailsDispute{
    width: 50%;
  }
  .additionalMessage{
    width: 49%;
    font-size: small;
  }
}


.warningModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  color: #333;
  gap: 1rem;
}

.warningModal h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: green; /* Warning red color */
  margin-bottom: 0.5rem;
}
.warningModal img{
  width: 150px;
  height: 150px;

}

.warningModal p {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .warningModal h2 {
    font-size: 1.25rem;
  }

  .warningModal p {
    font-size: 0.9rem;
  }
}

