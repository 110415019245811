.signupContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f8f8;
}

.signupForm {
  max-width: 640px;
  padding: 2rem;
  border-radius: 8px;
}

.signupForm h2 {
  margin-bottom: 1rem;
}

.signupForm p {
  margin-bottom: 2rem;
  color: #666;
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formGroup label {
  display: block;
  margin-bottom: 0.5rem;
}

/* .formGroup input,
.contactNumber select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
} */

.formGroup1 {
  margin-bottom: 1.5rem;
  display: flex;
  gap: 24px;
}

/* .firstName {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.lastName {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
} */

.contactNumber {
  display: flex;
  gap: 0.75rem;
}

.contactNumber select {
  flex: 1;
  border-radius: 0.5rem;
  border: 1px solid #d5d5d5;
  background: #fafafa;;
  padding: 0 0.25rem;
  cursor: pointer;

    /* Add these Safari-specific fixes */
    -webkit-appearance: none;  /* Removes default Safari styling */
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>");  /* Custom dropdown arrow */
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    padding-right: 2rem;  /* Space for the dropdown arrow */
    min-width: 10rem;
    width: 100%;  /* Explicit width for Safari */
    color: inherit;  
}

.contactNumber select:focus{
  outline: none;
}

.placeholder{
  color: #797979;
}

/* .password{
  border-radius: 12px;
  border: 1px solid #d5d5d5;
  background: #fafafa;
} */

.contactNumber input {
  flex: 2;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.options input[type="checkbox"] {
  margin-right: 0.5rem;
}

.options label {
  /* margin-bottom: 0.5rem; */
}

.signupButton {
  width: 100%;
  padding: 0.75rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

.loginText {
  text-align: center;
  margin-bottom: 2rem;
}

.loginText a {
  color: var(--app-grey);
  text-decoration: none;
}

.loginText a:hover {
  text-decoration: underline;
}

.orSignUpWith {
  text-align: center;
  margin-bottom: 1rem;
  color: #666;
}

.socialButtons {
  display: flex;
  justify-content: space-between;
}

.socialButton {
  flex: 1;
  cursor: pointer;
  margin: 0 0.25rem;
}

.socialButton:hover {
  background-color: #e1e1e1;
}
.carouselContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2rem;
}

.carousel {
  position: relative;
  width: 31.25rem;
  height: 33.25rem;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  position: relative;
}

.slide.active {
  display: flex;
}

.slide p{
  font-family: Butler;
  font-size: 28px;
  font-weight: 500;
  line-height: 43.2px;
  text-align: left;
  color: wheat;
  position: absolute;
  bottom: 10%;
  left: 5%;
  width: 70%;
 
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  cursor: pointer;
}

.activeDot {
  background-color: #333;
}

/* Tablet View */
@media (max-width: 1024px) {

  .signupContainer{
    flex-direction: column;
  }

  .signupForm {
    padding: 1.5rem;
  }

  .formGroup1 {
    flex-direction: column;
  }

  .contactNumber {
    flex-direction: column;
  }

  .contactNumber select {
    padding: 0.625rem 0.25rem;
  }

  .carousel {
    width: 100%;
  }

  .carouselContainer {
    margin-left: 0;
    margin-top: 2rem;
    width: 80%;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .signupForm {
    padding: 1rem;
  }

  .formGroup1 {
    flex-direction: column;
    gap: 12px;
  }

  .contactNumber {
    flex-direction: column;
  }

  .carousel {
    width: 100%;
    height: 200px;
  }

  .carouselContainer {
    margin-left: 0;
    margin-top: 2rem;
  }
}

@media (max-width:360px) {
  .signupForm {
    width: 95%;
  }
}
