.linkReset {
  text-decoration: none; /* Remove default underline */
  color: inherit; /* Keep text color the same */
  display: block; /* Behave like a div */
  width: 100%;
}

.linkReset:hover,
.linkReset:focus {
  text-decoration: none;
  color: inherit;
}

.cardContainer {
  width: 100%;
  max-width: 45.375rem;
  background: #f6f6f6;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 1.5rem 1.675rem;
  max-height: 700px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.imageContainer {
  position: relative;
  /* padding-top: 56.25%; 16:9 Aspect Ratio */
  overflow: hidden;
  border-radius: 0.75rem;
  width: 100%;
  height: 480px;
}

.image {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: contain;

}

.categoryTag {
  position: absolute;
  top: 3.75rem;
  right: 1.25rem;
  background-color: var(--app-dark-gold);/* Customize the color */
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 600;
}

.cardContent {
  padding-top: 2rem;
  padding-left: 2.5rem;
  padding-right: 3.5rem;
}

.date {
  color: #888;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.title {
  font-family: Butler;
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* limits to 2 lines */
  -webkit-box-orient: vertical;
  white-space: normal; /* allows multiple lines */
}

.desc{
  font-family: Fraunces;
  font-size: 1rem;
  margin-bottom: 1.875rem;
}

.authorSection {
  display: flex;
  align-items: center;
}

/* .authorImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
} */

.authorName {
  font-size: 1rem;
  font-weight: 500;
}

.authorNameDetails{
  display: flex;
  flex-direction: column;
  column-gap: 4px;
  align-items: flex-start;
  justify-content: center;
  margin-left: 0.5rem;
}

.authorNameDetails h2{
  margin: 0;
  padding: 0;
  font-family: Fraunces;
  font-size: 16px;
  font-weight: 600;
  line-height: 17.26px;
}

.authorNameDetails p{
  margin: 0;
  padding: 0;
  color: #666666;
  font-family: Fraunces;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.001em;
  text-align: left;
}


img.flag{
  width:60px
}

.flag { 
  display: inline-block; 
  background-repeat: no-repeat;
}

.flag.flag-64 { 
    display: inline-block; 
    width: 60px;
    height: 60px;
    background-image: url(/public/Assets/Images/Banners/sprite-flags.png); 
    background-repeat: no-repeat;
 }
.flag.flag-64.flag-abkhazia { background-position: -0px -0px; }
.flag.flag-64.flag-basque-country { background-position: -64px -0px; }
.flag.flag-64.flag-british-antarctic-territory { background-position: -128px -0px; }
.flag.flag-64.flag-commonwealth { background-position: -192px -0px; }
.flag.flag-64.flag-england { background-position: -256px -0px; }
.flag.flag-64.flag-gosquared { background-position: -320px -0px; }
.flag.flag-64.flag-kosovo { background-position: -384px -0px; }
.flag.flag-64.flag-mars { background-position: -448px -0px; }
.flag.flag-64.flag-nagorno-karabakh { background-position: -512px -0px; }
.flag.flag-64.flag-nato { background-position: -576px -0px; }
.flag.flag-64.flag-northern-cyprus { background-position: -640px -0px; }
.flag.flag-64.flag-olympics { background-position: -704px -0px; }
.flag.flag-64.flag-red-cross { background-position: -768px -0px; }
.flag.flag-64.flag-scotland { background-position: -832px -0px; }
.flag.flag-64.flag-somaliland { background-position: -896px -0px; }
.flag.flag-64.flag-south-ossetia { background-position: -960px -0px; }
.flag.flag-64.flag-united-nations { background-position: -1024px -0px; }
.flag.flag-64.flag-unknown { background-position: -0px -64px; }
.flag.flag-64.flag-wales { background-position: -64px -64px; }
.flag.flag-64.flag-ad { background-position: -128px -64px; }
.flag.flag-64.flag-ae { background-position: -192px -64px; }
.flag.flag-64.flag-af { background-position: -256px -64px; }
.flag.flag-64.flag-ag { background-position: -320px -64px; }
.flag.flag-64.flag-ai { background-position: -384px -64px; }
.flag.flag-64.flag-al { background-position: -448px -64px; }
.flag.flag-64.flag-am { background-position: -512px -64px; }
.flag.flag-64.flag-an { background-position: -576px -64px; }
.flag.flag-64.flag-ao { background-position: -640px -64px; }
.flag.flag-64.flag-aq { background-position: -704px -64px; }
.flag.flag-64.flag-ar { background-position: -768px -64px; }
.flag.flag-64.flag-as { background-position: -832px -64px; }
.flag.flag-64.flag-at { background-position: -896px -64px; }
.flag.flag-64.flag-au { background-position: -960px -64px; }
.flag.flag-64.flag-aw { background-position: -1024px -64px; }
.flag.flag-64.flag-ax { background-position: -0px -128px; }
.flag.flag-64.flag-az { background-position: -64px -128px; }
.flag.flag-64.flag-ba { background-position: -128px -128px; }
.flag.flag-64.flag-bb { background-position: -192px -128px; }
.flag.flag-64.flag-bd { background-position: -256px -128px; }
.flag.flag-64.flag-be { background-position: -320px -128px; }
.flag.flag-64.flag-bf { background-position: -384px -128px; }
.flag.flag-64.flag-bg { background-position: -448px -128px; }
.flag.flag-64.flag-bh { background-position: -512px -128px; }
.flag.flag-64.flag-bi { background-position: -576px -128px; }
.flag.flag-64.flag-bj { background-position: -640px -128px; }
.flag.flag-64.flag-bl { background-position: -704px -128px; }
.flag.flag-64.flag-bm { background-position: -768px -128px; }
.flag.flag-64.flag-bn { background-position: -832px -128px; }
.flag.flag-64.flag-bo { background-position: -896px -128px; }
.flag.flag-64.flag-br { background-position: -960px -128px; }
.flag.flag-64.flag-bs { background-position: -1024px -128px; }
.flag.flag-64.flag-bt { background-position: -0px -192px; }
.flag.flag-64.flag-bw { background-position: -64px -192px; }
.flag.flag-64.flag-by { background-position: -128px -192px; }
.flag.flag-64.flag-bz { background-position: -192px -192px; }
.flag.flag-64.flag-ca { background-position: -256px -192px; }
.flag.flag-64.flag-cc { background-position: -320px -192px; }
.flag.flag-64.flag-cd { background-position: -384px -192px; }
.flag.flag-64.flag-cf { background-position: -448px -192px; }
.flag.flag-64.flag-cg { background-position: -512px -192px; }
.flag.flag-64.flag-ch { background-position: -576px -192px; }
.flag.flag-64.flag-ci { background-position: -640px -192px; }
.flag.flag-64.flag-ck { background-position: -704px -192px; }
.flag.flag-64.flag-cl { background-position: -768px -192px; }
.flag.flag-64.flag-cm { background-position: -832px -192px; }
.flag.flag-64.flag-cn { background-position: -896px -192px; }
.flag.flag-64.flag-co { background-position: -960px -192px; }
.flag.flag-64.flag-cr { background-position: -1024px -192px; }
.flag.flag-64.flag-cu { background-position: -0px -256px; }
.flag.flag-64.flag-cv { background-position: -64px -256px; }
.flag.flag-64.flag-cw { background-position: -128px -256px; }
.flag.flag-64.flag-cx { background-position: -192px -256px; }
.flag.flag-64.flag-cy { background-position: -256px -256px; }
.flag.flag-64.flag-cz { background-position: -320px -256px; }
.flag.flag-64.flag-de { background-position: -384px -256px; }
.flag.flag-64.flag-dj { background-position: -448px -256px; }
.flag.flag-64.flag-dk { background-position: -512px -256px; }
.flag.flag-64.flag-dm { background-position: -576px -256px; }
.flag.flag-64.flag-do { background-position: -640px -256px; }
.flag.flag-64.flag-dz { background-position: -704px -256px; }
.flag.flag-64.flag-ec { background-position: -768px -256px; }
.flag.flag-64.flag-ee { background-position: -832px -256px; }
.flag.flag-64.flag-eg { background-position: -896px -256px; }
.flag.flag-64.flag-eh { background-position: -960px -256px; }
.flag.flag-64.flag-er { background-position: -1024px -256px; }
.flag.flag-64.flag-es { background-position: -0px -320px; }
.flag.flag-64.flag-et { background-position: -64px -320px; }
.flag.flag-64.flag-eu { background-position: -128px -320px; }
.flag.flag-64.flag-fi { background-position: -192px -320px; }
.flag.flag-64.flag-fj { background-position: -256px -320px; }
.flag.flag-64.flag-fk { background-position: -320px -320px; }
.flag.flag-64.flag-fm { background-position: -384px -320px; }
.flag.flag-64.flag-fo { background-position: -448px -320px; }
.flag.flag-64.flag-fr { background-position: -512px -320px; }
.flag.flag-64.flag-ga { background-position: -576px -320px; }
.flag.flag-64.flag-gb { background-position: -640px -320px; }
.flag.flag-64.flag-gd { background-position: -704px -320px; }
.flag.flag-64.flag-ge { background-position: -768px -320px; }
.flag.flag-64.flag-gg { background-position: -832px -320px; }
.flag.flag-64.flag-gh { background-position: -896px -320px; }
.flag.flag-64.flag-gi { background-position: -960px -320px; }
.flag.flag-64.flag-gl { background-position: -1024px -320px; }
.flag.flag-64.flag-gm { background-position: -0px -384px; }
.flag.flag-64.flag-gn { background-position: -64px -384px; }
.flag.flag-64.flag-gq { background-position: -128px -384px; }
.flag.flag-64.flag-gr { background-position: -192px -384px; }
.flag.flag-64.flag-gs { background-position: -256px -384px; }
.flag.flag-64.flag-gt { background-position: -320px -384px; }
.flag.flag-64.flag-gu { background-position: -384px -384px; }
.flag.flag-64.flag-gw { background-position: -448px -384px; }
.flag.flag-64.flag-gy { background-position: -512px -384px; }
.flag.flag-64.flag-hk { background-position: -576px -384px; }
.flag.flag-64.flag-hn { background-position: -640px -384px; }
.flag.flag-64.flag-hr { background-position: -704px -384px; }
.flag.flag-64.flag-ht { background-position: -768px -384px; }
.flag.flag-64.flag-hu { background-position: -832px -384px; }
.flag.flag-64.flag-ic { background-position: -896px -384px; }
.flag.flag-64.flag-id { background-position: -960px -384px; }
.flag.flag-64.flag-ie { background-position: -1024px -384px; }
.flag.flag-64.flag-il { background-position: -0px -448px; }
.flag.flag-64.flag-im { background-position: -64px -448px; }
.flag.flag-64.flag-in { background-position: -128px -448px; }
.flag.flag-64.flag-iq { background-position: -192px -448px; }
.flag.flag-64.flag-ir { background-position: -256px -448px; }
.flag.flag-64.flag-is { background-position: -320px -448px; }
.flag.flag-64.flag-it { background-position: -384px -448px; }
.flag.flag-64.flag-je { background-position: -448px -448px; }
.flag.flag-64.flag-jm { background-position: -512px -448px; }
.flag.flag-64.flag-jo { background-position: -576px -448px; }
.flag.flag-64.flag-jp { background-position: -640px -448px; }
.flag.flag-64.flag-ke { background-position: -704px -448px; }
.flag.flag-64.flag-kg { background-position: -768px -448px; }
.flag.flag-64.flag-kh { background-position: -832px -448px; }
.flag.flag-64.flag-ki { background-position: -896px -448px; }
.flag.flag-64.flag-km { background-position: -960px -448px; }
.flag.flag-64.flag-kn { background-position: -1024px -448px; }
.flag.flag-64.flag-kp { background-position: -0px -512px; }
.flag.flag-64.flag-kr { background-position: -64px -512px; }
.flag.flag-64.flag-kw { background-position: -128px -512px; }
.flag.flag-64.flag-ky { background-position: -192px -512px; }
.flag.flag-64.flag-kz { background-position: -256px -512px; }
.flag.flag-64.flag-la { background-position: -320px -512px; }
.flag.flag-64.flag-lb { background-position: -384px -512px; }
.flag.flag-64.flag-lc { background-position: -448px -512px; }
.flag.flag-64.flag-li { background-position: -512px -512px; }
.flag.flag-64.flag-lk { background-position: -576px -512px; }
.flag.flag-64.flag-lr { background-position: -640px -512px; }
.flag.flag-64.flag-ls { background-position: -704px -512px; }
.flag.flag-64.flag-lt { background-position: -768px -512px; }
.flag.flag-64.flag-lu { background-position: -832px -512px; }
.flag.flag-64.flag-lv { background-position: -896px -512px; }
.flag.flag-64.flag-ly { background-position: -960px -512px; }
.flag.flag-64.flag-ma { background-position: -1024px -512px; }
.flag.flag-64.flag-mc { background-position: -0px -576px; }
.flag.flag-64.flag-md { background-position: -64px -576px; }
.flag.flag-64.flag-me { background-position: -128px -576px; }
.flag.flag-64.flag-mf { background-position: -192px -576px; }
.flag.flag-64.flag-mg { background-position: -256px -576px; }
.flag.flag-64.flag-mh { background-position: -320px -576px; }
.flag.flag-64.flag-mk { background-position: -384px -576px; }
.flag.flag-64.flag-ml { background-position: -448px -576px; }
.flag.flag-64.flag-mm { background-position: -512px -576px; }
.flag.flag-64.flag-mn { background-position: -576px -576px; }
.flag.flag-64.flag-mo { background-position: -640px -576px; }
.flag.flag-64.flag-mp { background-position: -704px -576px; }
.flag.flag-64.flag-mq { background-position: -768px -576px; }
.flag.flag-64.flag-mr { background-position: -832px -576px; }
.flag.flag-64.flag-ms { background-position: -896px -576px; }
.flag.flag-64.flag-mt { background-position: -960px -576px; }
.flag.flag-64.flag-mu { background-position: -1024px -576px; }
.flag.flag-64.flag-mv { background-position: -0px -640px; }
.flag.flag-64.flag-mw { background-position: -64px -640px; }
.flag.flag-64.flag-mx { background-position: -128px -640px; }
.flag.flag-64.flag-my { background-position: -192px -640px; }
.flag.flag-64.flag-mz { background-position: -256px -640px; }
.flag.flag-64.flag-na { background-position: -320px -640px; }
.flag.flag-64.flag-nc { background-position: -384px -640px; }
.flag.flag-64.flag-ne { background-position: -448px -640px; }
.flag.flag-64.flag-nf { background-position: -512px -640px; }
.flag.flag-64.flag-ng { background-position: -576px -640px; }
.flag.flag-64.flag-ni { background-position: -640px -640px; }
.flag.flag-64.flag-nl { background-position: -704px -640px; }
.flag.flag-64.flag-no { background-position: -768px -640px; }
.flag.flag-64.flag-np { background-position: -832px -640px; }
.flag.flag-64.flag-nr { background-position: -896px -640px; }
.flag.flag-64.flag-nu { background-position: -960px -640px; }
.flag.flag-64.flag-nz { background-position: -1024px -640px; }
.flag.flag-64.flag-om { background-position: -0px -704px; }
.flag.flag-64.flag-pa { background-position: -64px -704px; }
.flag.flag-64.flag-pe { background-position: -128px -704px; }
.flag.flag-64.flag-pf { background-position: -192px -704px; }
.flag.flag-64.flag-pg { background-position: -256px -704px; }
.flag.flag-64.flag-ph { background-position: -320px -704px; }
.flag.flag-64.flag-pirate-black { background-position: -384px -704px; }
.flag.flag-64.flag-pirate-white { background-position: -448px -704px; }
.flag.flag-64.flag-pk { background-position: -512px -704px; }
.flag.flag-64.flag-pl { background-position: -576px -704px; }
.flag.flag-64.flag-pn { background-position: -640px -704px; }
.flag.flag-64.flag-pr { background-position: -704px -704px; }
.flag.flag-64.flag-ps { background-position: -768px -704px; }
.flag.flag-64.flag-pt { background-position: -832px -704px; }
.flag.flag-64.flag-pw { background-position: -896px -704px; }
.flag.flag-64.flag-py { background-position: -960px -704px; }
.flag.flag-64.flag-qa { background-position: -1024px -704px; }
.flag.flag-64.flag-ro { background-position: -0px -768px; }
.flag.flag-64.flag-rs { background-position: -64px -768px; }
.flag.flag-64.flag-ru { background-position: -128px -768px; }
.flag.flag-64.flag-rw { background-position: -192px -768px; }
.flag.flag-64.flag-sa { background-position: -256px -768px; }
.flag.flag-64.flag-sb { background-position: -320px -768px; }
.flag.flag-64.flag-sc { background-position: -384px -768px; }
.flag.flag-64.flag-sd { background-position: -448px -768px; }
.flag.flag-64.flag-se { background-position: -512px -768px; }
.flag.flag-64.flag-sg { background-position: -576px -768px; }
.flag.flag-64.flag-sh { background-position: -640px -768px; }
.flag.flag-64.flag-si { background-position: -704px -768px; }
.flag.flag-64.flag-sk { background-position: -768px -768px; }
.flag.flag-64.flag-sl { background-position: -832px -768px; }
.flag.flag-64.flag-sm { background-position: -896px -768px; }
.flag.flag-64.flag-sn { background-position: -960px -768px; }
.flag.flag-64.flag-so { background-position: -1024px -768px; }
.flag.flag-64.flag-sr { background-position: -0px -832px; }
.flag.flag-64.flag-ss { background-position: -64px -832px; }
.flag.flag-64.flag-st { background-position: -128px -832px; }
.flag.flag-64.flag-sv { background-position: -192px -832px; }
.flag.flag-64.flag-sy { background-position: -256px -832px; }
.flag.flag-64.flag-sz { background-position: -320px -832px; }
.flag.flag-64.flag-tc { background-position: -384px -832px; }
.flag.flag-64.flag-td { background-position: -448px -832px; }
.flag.flag-64.flag-tf { background-position: -512px -832px; }
.flag.flag-64.flag-tg { background-position: -576px -832px; }
.flag.flag-64.flag-th { background-position: -640px -832px; }
.flag.flag-64.flag-tj { background-position: -704px -832px; }
.flag.flag-64.flag-tk { background-position: -768px -832px; }
.flag.flag-64.flag-tl { background-position: -832px -832px; }
.flag.flag-64.flag-tm { background-position: -896px -832px; }
.flag.flag-64.flag-tn { background-position: -960px -832px; }
.flag.flag-64.flag-to { background-position: -1024px -832px; }
.flag.flag-64.flag-tr { background-position: -0px -896px; }
.flag.flag-64.flag-tt { background-position: -64px -896px; }
.flag.flag-64.flag-tv { background-position: -128px -896px; }
.flag.flag-64.flag-tw { background-position: -192px -896px; }
.flag.flag-64.flag-tz { background-position: -256px -896px; }
.flag.flag-64.flag-ua { background-position: -320px -896px; }
.flag.flag-64.flag-ug { background-position: -384px -896px; }
.flag.flag-64.flag-us { background-position: -448px -896px; }
.flag.flag-64.flag-uy { background-position: -512px -896px; }
.flag.flag-64.flag-uz { background-position: -576px -896px; }
.flag.flag-64.flag-va { background-position: -640px -896px; }
.flag.flag-64.flag-vc { background-position: -704px -896px; }
.flag.flag-64.flag-ve { background-position: -768px -896px; }
.flag.flag-64.flag-vg { background-position: -832px -896px; }
.flag.flag-64.flag-vi { background-position: -896px -896px; }
.flag.flag-64.flag-vn { background-position: -960px -896px; }
.flag.flag-64.flag-vu { background-position: -1024px -896px; }



@media(max-width:1024px){
  .title{
    font-size: 1rem;
  }
}



@media (max-width: 768px) {
  .cardContainer {
  }

  /* .imageContainer {
    height: 60%;
  } */

  /* .imageContainer .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  } */


  .categoryTag {
    font-size: 0.7rem;
    padding: 4px 8px;
  }

  .date, .authorName {
    font-size: 0.875rem;
  }

  .title {
    font-size: 1rem;
  }

  .authorImage {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .cardContainer {
    max-width: 100%;
    padding: 0.5rem;
  }
  
  .imageContainer .image{
    object-fit: cover;
  }

  .categoryTag {
    font-size: 0.875rem;
    padding: 6px 10px;
  }

  .date, .authorName {
    font-size: 0.75rem;
  }

  .title {
    font-size: 0.875rem;
  }

  .authorImage {
    width: 25px;
    /* height: 30px; */
    border-radius: 10%;
  }

  .cardContent {
    padding: 0.5rem;
  }

  .categoryTag {
    top: 0.75rem;
    right: 0.25rem;
    background-color: var(--app-dark-gold);
  }
}

@media (max-width:380px){
  .cardContainer{
    width: 80vw;
  }
}
