.modal {
  padding: 0.75rem;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 90%;
  height: 25dvh;
  background: linear-gradient(
    180.02deg,
    rgb(0 0 0 / 95%) -34.04%,
    rgb(55 17 28) 8.64%,
    rgb(84 26 43 / 96%) 35.35%,
    rgb(138 29 61 / 98%) 70.69%,
    rgb(253 99 151 / 91%) 123.06%
  );
  border: 1px solid #f7f0da;
}

.icon {
  font-size: 1.25rem;
  cursor: pointer;
  align-self: flex-end;
  color: var(--app-white);
}

.title {
  font-size: 1.75rem;
  color: var(--app-white);
  font-family: "Butler";
  font-weight: 700;
  text-align: center;
}

.description {
  font-family: "Fraunces";
  font-size: 1rem;
  color: var(--app-white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  padding: 1.875rem 0;
}

.actions {
  background-color: var(--app-black);
  border-radius: 2rem;
  height: 2.5rem;
  width: 70%;
}

.actions > input {
  background-color: transparent;
  border-radius: 2rem;
  outline: none;
  border: none;
  height: 2.5rem;
  width: 70%;
  color: var(--app-white);
  font-family: "Fraunces";
  font-size: 0.875rem;
  padding-left: 1.25rem;
}

.actions > input::placeholder {
  color: var(--app-white);
}

.actions > button {
  background-color: var(--app-white);
  color: var(--app-black);
  border-radius: 2rem;
  height: 2.5rem;
  width: 30%;
  font-family: "Fraunces";
  font-size: 0.875rem;
}

@media (max-width: 768px) {
  .title {
    font-size: 1.25rem;
  }

  .description {
    font-size: 0.75rem;
  }
}

@media (max-width: 560px) {
  .modal {
    width: 100%;
  }
  .title {
    font-size: 1.15rem;
  }

  .description {
    font-size: 0.875rem;
  }
}
