/* src/components/Banner.module.css */

.banner {
    position: relative;
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: white;
    padding-top: 50px;
    padding-left: 50px;
}

.textContainer {
    text-align: left;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.heading {
    color: white;
    font-family: "Butler";
    font-size: 55px;
    font-weight: 700;
    line-height: 55px;
    text-align: left;

}

.description {
    font-family: "Fraunces";
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;    
    color: white;
}

.button {
    color: white;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    width: 120px;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: rgb(158, 135, 93);
    color: var(--app-dark);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .banner {
        height: 400px;
        background-size: cover;
    }
    
    .heading {
        font-size: 2rem;
    }
    
    .description {
        font-size: 1rem;
    }

}

@media (max-width: 480px) {
    .banner {
        height: 300px;
        background-size: cover;
    }

    .textContainer {
        max-width: 100%;
    }

    .heading {
        font-size: 1rem;
        line-height:1rem ;
    }

    .description {
        font-size: 0.9rem;
    }

    .button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}
