.container{
    width: 30vw;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 2;
    margin-top: 0;
    font-family: "Fraunces";
    border-radius: 20px;
    border: 5px solid;
    border-color: rgb(126, 6, 6);
    transform: translateX(100%);
    transition: transform 0.4s ease-in-out;
    max-height: 85dvh;
}

.container.open {
    transform: translateX(0);
  }

.headerSection{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    background: linear-gradient(180deg, #971E42 0%, #711732 37%, #310A16 100%);
    color: white;
    border-radius: 10px 10px;
    padding: 1.5rem;
}

.headerContent{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.heading{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: "Butler";
}

.mainCross{
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
    border-radius: 50%;
}

.cross{
    font-size: 1rem;
    cursor: pointer;
    color: gray;
    -webkit-tap-highlight-color : transparent !important;
}

.xMark,.cross {
    outline: none; /* Remove default focus outline */
    -webkit-tap-highlight-color: transparent !important; /* Remove blue flash on mobile devices */
    cursor: pointer; /* Ensure it looks clickable */
}

.subtotal{
    display: flex;
    justify-content: space-between;
}
.subtotal p{
    color: white;
}
.subtotal span{
    font-weight: 600;
}

.cartItemsList{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 65%;
    width: 100%;
    justify-content: space-evenly;
    overflow: auto;
    background-color: white;
    padding: 1rem;
    border-radius: 20px;
}

.cartItemCard{
    background: rgba(246, 246, 246, 1);
    display: flex;
    gap: 0.85rem;
    width: 100%;
    justify-content: space-around;
    border-radius: 10px;
    padding: 1rem;
}

.bookImage{
    width: 80px;
    height: 100px;
    border-radius: 1rem;
}

.bookImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.bookDetails{
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 70%;
}

.header{
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
}

.itemDetails{
    color: grey;
}

.price{
    font-weight: 700;
}

.drawerIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;    
    height: 40px;
    background-color: #FFF3E9;
    padding: 10px;
    border-radius: 50%;

    position: fixed;
    top: 15rem;
    right: 0;
    z-index: 1;
}
.selectQty {
    display: flex;
    gap: 1rem;
    justify-content: space-around;
    align-items: center;
    border: 1px solid var(--app-claret);
    border-radius: 0.625rem;
    padding: 0.5rem 0.25rem;
    background-color: white;
    width: 110px;
    font-size: 12px;
}

.selectQty h2 {
    font-family: Fraunces;
    font-size: 1.1875rem;
    font-weight: 400;
    line-height: 1.2;
    text-align: left;
    color: var(--app-black);
    margin-bottom: 0;
}

.qtyButton {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: var(--app-light-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.qtyButton p {
    /* margin: 0;
    padding: 0; */
    font-family: Butler;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
}

.title{
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 40px;
    max-width: 500px;
}

.itemDetails{
    font-size: 12px;
}

@media (max-width:1024px){
    .container{
        width: 40vw;
    }
}
@media(max-width:768px){
    .container{
        width: 55vw;
        height: 70vh;
    }
}

@media (max-width:576px){
    .container{
        width: 65vw;
    }
    .bookDetails{
        font-size: 0.85rem;
    }
}

@media (max-width:420px){
    .container{
        width: 85vw;
    }
}