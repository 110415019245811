.crumbs{
    color: var(--app-dark);
}

.mainContainer{
    margin: 2rem 2.5rem;
}

.headerSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

.headerSection p{
    font-family: Fraunces;
    font-size: 24px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #2E3646;
}

.header{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.header h2{
    font-family: Butler;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -0.01em;
    text-align: center;
    color: var(--app-dark);
}

.mainSection{
    width: 100%;
}

.tickIcon{
    font-size: 2rem;
}

.crossIcon {
    font-size: 2rem;
    color: var(--app-warning);
}

.order{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1.875rem;
    border-bottom: 1px solid #000000;
}

.order p{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: var(--app-dark);
}

.order .btnContainer{
    display: flex;
    gap: 1rem;
    margin-top: 0.75rem;
}

.order .btnContainer > button{
    width: 11rem;
}

.shippingDetails h2{
    font-family: Butler;
    font-size: 24px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--app-dark);
}

.address{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    max-width: 560px;
}

.address h2{
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #141718;
}

.address p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: inherit;
}

.sidebar{
    background: var(--app-light-grey);
    padding: 1.5rem;
    flex: 0.4;
    height: fit-content;
    width: 100%;
    overflow: auto;
}

.sidebar h2{
    font-family: Fraunces;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: var(--app-dark);
}

.orderItem{
    display: flex;
    gap: 7rem;
    align-items: flex-start;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #cecccc;
    width: 100%;
}

.productImage {
    width:7rem;
    height: 7rem;
    margin-top: 0.25rem;
}

.productImage > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product {
    display: flex;
    gap: 1rem;
}

.otherDetails {
    display: flex;
    gap: 4.25rem;
}

.detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
}

.orderSummary {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding-top: 1.5rem;
}

.paymentSummary {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.total {
    width: 20%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
}

.productDetails {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.productDetails h3 {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 60px;
    max-width: 500px;
}

@media (min-width: 769px){
    .orderDetails{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .order{
        border: none;
    }
}

@media (max-width:1024px) {
    .productDetails h3, .detail h3,.total h3 {
        font-size: 0.875rem;
    }

    .productDetails p, .detail p, .total p{
        font-size: 0.75rem;
    }

    .orderItem {
        gap: 4rem;
    }

    .total {
        width: 75%;
    }
}

@media (max-width:768px){
    .mainContainer{
        margin: 1rem 1.5rem;
    }
    .mainSection{
        flex-direction: column;
        align-items: center;

    }
    .btnContainer{
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .sidebar{
        width: 100%;
        overflow: auto;
    }
    .headerSection p {
        line-height: 30px;
    }

    .order p {
        line-height: 20px;
    }
    
}