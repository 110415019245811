
.footerContainer {
    background: #110D06;
    display: flex;
    flex-direction: column;
    margin-top: 13rem;
}

.communityContainer{
    position: relative;
    top: -6rem;
}

.mainContainer {
    margin: 0px 153px 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.icon {
    display: flex;
    align-items: center;
    width: 3.5rem;
}

.icon > img {
    width: 100%;
    object-fit: contain;
}

.itemContainer {
    display: flex;
    gap: 1.25rem;
    flex-wrap: wrap;
}

.footerSocial {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
}

.footerSocial h2 {
    color: #ffffff;
    font-family: Fraunces;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.19px;
    text-align: left;
    margin-bottom: 0.75rem;
}

.socialIcons {
    display: flex;
    gap: 1rem;
}

.iconContainer {
    display: flex;
    gap: 0.5rem;
}

.footerIcons{
    color: var(--app-white);
    font-size: 1.5rem;
    cursor: pointer;
}

.footerIcons:hover{
    transform: scale(1.1);
}

.icons {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #D9D9D9;
    overflow: hidden;
}

.icons img{
    width: inherit;
    height: inherit;
}

/* Responsive styles */

@media (max-width: 1200px) {
    .mainContainer {
        margin: 0 100px 50px;
    }

    .itemContainer {
        flex-direction: column;
    }

    .footerSocial {
        flex-direction: column;
    }
}

@media (max-width: 992px) {
    .mainContainer {
        margin: 0 60px 40px;
    }

    .itemContainer {
        gap: 0.75rem;
    }

    .socialIcons {
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .iconContainer {
        flex-wrap: wrap;
        gap: 0.5rem;
    }
}

@media (max-width: 768px) {
    .footerContainer{
        margin-top: 2.5rem;
    }
    .mainContainer {
        margin: 30px 30px 30px;
        flex-direction: column;
        align-items: flex-start;
        padding: 1.5rem 2rem;
        gap: 2rem;
    }

    .communityContainer{
        position: relative;
        top: 1rem;
    }

    .itemContainer {
        gap: 1rem;
        justify-content: center;
        width: 100%;
    }

    .socialIcons {
        justify-content: flex-start;
    }

    .iconContainer {
        justify-content: flex-start;
    }
}

@media (max-width: 576px) {
    .footerContainer{
        margin-top: 1.5rem;
    }
    .footerSocial h2 {
        font-size: 16px;
        line-height: 20px;
    }

    .icons {
        width: 1.5rem;
        height: 1.5rem;
    }

    .socialIcons {
        gap: 0.5rem;
    }

    .icon {
        display: flex;
        align-items: center;
        width: 2.75rem;
    }
}


