.header {
    position: relative;
    background-image: url(/public/Assets/Images/contactusBanner.svg);
    background-color: rgba(0, 0, 0, 0.5);
    background-repeat: no-repeat;
    background-size:cover;
    width: 100%;
    text-align: center;
    color: #ffffff;
    overflow: hidden; /* Prevents any overflow */
}

.BreadCrumb {
    position: absolute;
}

.header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
    z-index: 1; /* Place the overlay above the background image */
}

.header h1,
.header p,
.content button,
.header .background {
    position: relative; /* Ensure content is above the overlay */
    z-index: 2;
}

.content{
    margin-right: auto;
    margin-left: auto;
    padding: 4.75rem 0 7rem;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
}

.content h1 {
    font-family: Butler;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.01em;
    text-align: center;
}

.header p{
    color: #ffffff;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 17.26px;
    text-align: center;
    font-style: italic;
}

.Btn{
    width: 126px;
}
/* Media queries for responsive design */
@media (min-width: 768px) {
    .header h1 {
        font-size: 2rem;
    }
}

@media (min-width: 1024px) {
    .header h1 {
        font-size: 2.5rem;
    }
}

@media (max-width: 768px){
    .BreadCrumb {
        position: static;
    }
    .content{
        padding: 1rem 0 3.75rem;
    }
}

@media (max-width: 576px){
    .content h1{
        font-size: 1.5rem;
        line-height: 1;
    }
    .content{
        gap: 1rem;
    }
    .header p{
        line-height: 14.26px;
        font-size: 14px;
    }
}

@media (max-width:428px){
    .content{
        width: 90%;
    }
}
