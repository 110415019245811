.container {
    padding: 1rem;
}

.container h1 {
    margin: 0;
    padding: 0;
    font-family: Butler;
    font-size: 21px;
    font-weight: 500;
    line-height: 25.2px;
    color: var(--app-black);
}

.detailContainer {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem; /* Reduce gap for better mobile view */
}

.details {
    display: flex;
    flex-direction: column;
    flex-basis: calc(50% - 2rem); /* Adjust width for better layout */
}

.details h2 {
    font-family: Butler;
    font-size: 18px;
    font-weight: 400;
    line-height: 19.73px;
    text-align: left;
}

.details p {
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.73px;
    text-align: left;
    color: #64615D;
}

.description {
    margin-top: 48px;
}

.description h2 {
    font-family: Butler;
    font-size: 21px;
    font-weight: 500;
    line-height: 25.2px;
    text-align: left;
}

.description p {
    margin-top: 12px;
    color: #64615D;
    font-family: Fraunces;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.73px;
    text-align: left;
}

/* Media Queries for Responsive Design */

@media (max-width: 768px) {
    .detailContainer {
        gap: 1rem;
    }

    .details {
        flex-basis: calc(50% - 1rem); /* Adjust width for tablet view */
    }

    .description {
        margin-top: 24px;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 18px;
        line-height: 21.6px;
    }

    .details h2, .details p {
        font-size: 14px;
        line-height: 17.73px;
    }

    .details {
        flex-basis: 100%; /* Full width for mobile view */
    }

    .description h2 {
        font-size: 18px;
        line-height: 21.6px;
    }

    .description p {
        font-size: 14px;
        line-height: 17.73px;
    }
}
